import React from 'react'

export default function Hero() {
  return (
    <div id="home">
      <div id="name">
        <div id="first"> Mika Nur</div>
        <div id="second">Software Engineer</div>
      </div>

    </div>
  )
}