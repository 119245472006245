import React from 'react'

export default function Skills() {
  return (
    <div id="skills">
      <h2 className="title">Skills</h2>
      <div id="pic">
        <div>
          <img className="lang" src="https://res.cloudinary.com/dvysqqdqe/image/upload/v1576370340/HTML_Logo_o4b90v.png" alt="HTML" />
          <p>HTML</p>
        </div>

        <div>
          <img className="lang" src="https://res.cloudinary.com/dvysqqdqe/image/upload/v1576365695/css3-logo-png-transparent_l16rcd.png" alt="CSS" />
          <p>CSS</p>
        </div>

        <div>
          <img className="lang" src="https://res.cloudinary.com/dvysqqdqe/image/upload/v1576379548/JavaScript-logo_w9cyg0.png" alt="JavaScript" />
          <p>JavaScript</p>
        </div>

        <div>
          <img className="lang" src="https://res.cloudinary.com/dvysqqdqe/image/upload/v1576369009/React.js_logo-512_fvbkt7.png" alt="React" />
          <p>React</p>
        </div>

        <div>
          <img className="lang" src="https://res.cloudinary.com/dvysqqdqe/image/upload/v1576366223/nodejs-new-pantone-black_vi59ja.png" alt="Node JS" />
          <p>Node JS</p>
        </div>

        <div>
          <img className="lang" src="https://res.cloudinary.com/dvysqqdqe/image/upload/v1576542097/Git-Logo-2Color_x1itvo.png" alt="Git" />
          <p>Git</p>
        </div>

        <div>
          <img className="lang" src="https://res.cloudinary.com/dvysqqdqe/image/upload/v1576542195/github_PNG20_jo9ii5.png" alt="GitHub" />
          <p>GitHub</p>
        </div>

        <div>
          <img className="lang" src="https://res.cloudinary.com/dvysqqdqe/image/upload/v1576366334/postgresql-logo_vwqpbn.png" alt="PostgreSQL" />
          <p>PostgreSQL</p>
        </div>

        <div>
          <img className="lang" src="https://d18qs7yq39787j.cloudfront.net/uploads/company/478/MongoDB_logo.png" alt="Sequelize" />
          <p>MongoDB</p>
        </div>

        <div>
          <img className="lang" src="https://res.cloudinary.com/dvysqqdqe/image/upload/v1576624115/expressjs-icon_fpznqt.svg" alt="Express" />
          <p>Express</p>
        </div>

        <div>
          <img className="lang" src="https://res.cloudinary.com/dvysqqdqe/image/upload/v1576366697/Ruby_logo_etuuhw.png" alt="Ruby" />
          <p>Ruby</p>
        </div>

        <div>
          <img className="lang" src="https://res.cloudinary.com/dvysqqdqe/image/upload/v1576367012/tech_rubyonrails_kpd226.png" alt="Ruby on Rails" />
          <p>Ruby on Rails</p>
        </div>

      </div>
    </div>
  )
}